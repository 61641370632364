import { StyleSpecification } from 'maplibre-gl';

export interface ModelsLayerSpecification {
  id: string;
  type: 'models';
  source: string;
  before: string;
  minzoom?: number;
  maxzoom?: number;
  paint?: {
    'models-shading'?: 'basic' | 'physical' | 'original';

    'models-base-color'?: string;
    'models-emissive-color'?: string; // This should be the same as the base color unless specified
    'models-emissive-intensity'?: number;

    'models-metalness'?: number;
    'models-roughness'?: number;

    'models-texture-map'?: 'on' | 'off'; // When 'on' and a texture map is available, the texture will be used instead of the base color
    'models-ao-map'?: 'on' | 'off'; // When 'on' and a ao map is available, the ao map will be used instead of the base color

    // 'models-texture-opacity'?: number;
    // 'models-texture-saturation'?: number;
    // 'models-texture-contrast'?: number;

    // 'models-generate-specular-map'?: 'on' | 'off';
    // 'models-specular-contrast'?: number;
  };
  layout?: {
    visibility?: 'visible' | 'none';
  };
}

export interface ModelsSourceSpecification {
  type: 'models';
  // "url"?: string; We'll enable that when we enable tileJSON parsing
  tiles: Array<string>;
  bounds?: [number, number, number, number];
  scheme?: 'xyz' | 'tms';
  minzoom?: number;
  maxzoom?: number;
  attribution?: string;
}

export interface ModeledStyleSpecification extends StyleSpecification {
  'custom-sources': { [_: string]: ModelsSourceSpecification };
  'custom-layers': ModelsLayerSpecification[];
}

export const isModeledStyleSpecification = (
  style: StyleSpecification
): style is ModeledStyleSpecification => {
  return (style as ModeledStyleSpecification)['custom-sources'] !== undefined;
};
