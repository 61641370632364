import { CustomLayerInterface, Map } from 'maplibre-gl';
import {
  AmbientLight,
  BackSide,
  Camera,
  DirectionalLight,
  Group,
  Material,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
  MeshStandardMaterial,
  Object3D,
  Scene,
  WebGLRenderer
} from 'three';
import { ModelsSource } from './ModelsSource';
import { ModelsLayerSpecification, ModelsSourceSpecification } from './Types';
import { isMesh } from './utils';

const scale = 1 / (2 * 20037508.34);
const translateX = 0.5;
const translateY = 0.5;

const MAPLIBRE_SCALE = new Matrix4();
MAPLIBRE_SCALE.set(scale, 0, 0, translateX, 0, -scale, 0, translateY, 0, 0, scale, 0, 0, 0, 0, 1);

interface ModelsLayerInterface extends CustomLayerInterface {
  type: 'custom';
  renderingMode: '3d';
}

const to_rads = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

export class ModelsLayer implements ModelsLayerInterface {
  id: string;

  type: 'custom';

  renderingMode: '3d';

  renderer: WebGLRenderer;

  scene: Scene;

  camera: Camera;

  tiles: Group;

  directionalLight: DirectionalLight;

  ambientLight: AmbientLight;

  map: Map;

  source: ModelsSource;

  minzoom: number;

  maxzoom: number;

  visible: boolean = true;

  _lambertMaterial = new MeshLambertMaterial();

  _standardMaterial = new MeshStandardMaterial();

  shadingType: 'basic' | 'physical' | 'original' = 'original';

  diffuseColor: string = '#ffffff';

  emissiveColor: string = '#000000';

  emissiveIntensity: number = 0;

  metalness: number = 0;

  roughness: number = 1;

  textureMap: 'on' | 'off' = 'on';

  _options: ModelsLayerSpecification;

  constructor(options: ModelsLayerSpecification, source: ModelsSourceSpecification) {
    this.id = options.id;
    this.type = 'custom';
    this.renderingMode = '3d';

    this._lambertMaterial.flatShading = true;
    this._lambertMaterial.alphaTest = 0.1;
    this._lambertMaterial.side = BackSide;

    this._standardMaterial.flatShading = true;
    this._standardMaterial.alphaTest = 0.1;
    this._standardMaterial.side = BackSide;

    this.source = new ModelsSource(this.id, source);

    this._options = options;
    this.updatePropeties(options);
  }

  updatePropeties(options: ModelsLayerSpecification) {
    this.visible = options?.layout?.visibility !== 'none';
    this.shadingType = options?.paint?.['models-shading'] || 'original';
    this.diffuseColor = options?.paint?.['models-base-color'] || '#ffffff';
    this.emissiveColor = options?.paint?.['models-emissive-color'] || '#000000';
    this.emissiveIntensity = options?.paint?.['models-emissive-intensity'] || 0;
    this.metalness = options?.paint?.['models-metalness'] || 0;
    this.roughness = options?.paint?.['models-roughness'] || 1;
    this.textureMap = options?.paint?.['models-texture-map'] || 'on';

    this.minzoom = options.minzoom || 0;
    this.maxzoom = options.maxzoom || 22;
  }

  update(newOptions: ModelsLayerSpecification, newSource: ModelsSourceSpecification) {
    this._options = newOptions;
    this.updatePropeties(newOptions);

    if (newSource.tiles !== this.source.tiles) {
      const source = new ModelsSource(this.id, newSource);

      source.onAdd(this.map, this.renderer);

      this.scene.remove(this.tiles);
      this.tiles = source.scene;
      this.scene.add(this.tiles);

      this.source = source;
    }
  }

  setDirectionalLight(r: number, phi: number, theta: number) {
    this.directionalLight.position.setFromSphericalCoords(r, to_rads(phi), to_rads(theta));
  }

  setDirectionialIntensity(intensity: number) {
    this.directionalLight.intensity = intensity;
  }

  setAmbientIntensity(intensity: number) {
    this.ambientLight.intensity = intensity;
  }

  onAdd(map: Map, gl: WebGLRenderingContext) {
    this.map = map;

    this.scene = new Scene();
    this.tiles = this.source.scene;
    this.camera = new Camera();

    this.directionalLight = new DirectionalLight(0xffffff, 0.5);
    this.setDirectionalLight(1.15, 90, 0);
    this.scene.add(this.directionalLight);

    this.ambientLight = new AmbientLight(0xffffff, 0.1);
    this.scene.add(this.ambientLight);

    this.scene.add(this.tiles);

    this.renderer = new WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true
    });
    this.renderer.autoClear = false;

    this.source.onAdd(map, this.renderer);
  }

  addModel(model: Object3D) {
    model.traverse((c) => {
      if (c.material) {
        c.material.side = BackSide;
      }
    });
    this.tiles.add(model);
  }

  render(gl: WebGLRenderingContext, matrix: ArrayLike<number>) {
    const visibility =
      this.map.transform.tileZoom >= this.minzoom && this.map.transform.tileZoom <= this.maxzoom;
    this.tiles.visible = this.visible && visibility;

    if ((this.visible && visibility) !== true) {
      return;
    }

    const light = this.map.style.light;

    const intensity = light.properties.get('intensity');
    this.directionalLight.intensity = intensity * Math.PI;
    this.ambientLight.intensity = (1 - intensity) * Math.PI;

    const position = light.properties.get('position');
    this.directionalLight.position.set(position.x, position.y, position.z);

    this.source.updateTiles();

    this.updateMaterials();

    const m = new Matrix4().fromArray(matrix);

    const fullMatrix = MAPLIBRE_SCALE.clone();
    fullMatrix.multiplyMatrices(m, fullMatrix);

    this.tiles.matrixAutoUpdate = false;
    this.tiles.matrix = fullMatrix;

    this.tiles.updateMatrixWorld(true);

    this.camera.projectionMatrix.identity();

    this.renderer.state.reset();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }

  setTransparency(value: number) {
    this.tiles.traverse((c) => {
      if (isMesh(c)) {
        const material = c.material as Material;
        material.opacity = value;
        material.transparent = true;
        material.needsUpdate = true;
      }
    });
  }

  getMaterial(mesh: Mesh): Material {
    const shading = this.shadingType;

    if (shading === 'basic') {
      return this._lambertMaterial;
    }
    if (shading === 'physical') {
      return this._standardMaterial;
    }

    if (mesh.userData.originalMaterial) {
      return mesh.userData.originalMaterial.clone();
    }

    return mesh.material;
  }

  updateMaterials() {
    this.tiles.traverse((c) => {
      if (isMesh(c)) {
        const material = this.getMaterial(c) as MeshLambertMaterial | MeshStandardMaterial;

        // material.color.set(this.diffuseColor);
        material.emissive.set(this.emissiveColor);
        material.emissiveIntensity = this.emissiveIntensity;

        if ((material as any).isMeshStandardMaterial) {
          const physicalMaterial = material as MeshStandardMaterial;
          physicalMaterial.metalness = this.metalness;
          physicalMaterial.roughness = this.roughness;
        }

        if (this.textureMap === 'off') {
          material.map = null;
        }

        c.material = material;
      }
    });
  }

  public get tilesGroup(): Group {
    return this.tiles;
  }
}
