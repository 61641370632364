import { Map } from 'maplibre-gl';
import { BackSide, Group, MeshStandardMaterial, REVISION, WebGLRenderer } from 'three';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { ModelsSourceSpecification } from './Types';
import { isMesh } from './utils';

const KTX2_LOADER = new KTX2Loader().setCrossOrigin('anonymous');
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
KTX2_LOADER.setTranscoderPath(`${THREE_PATH}/examples/jsm/libs/basis/`);

export class ModelsSource {
  type: 'model';

  id: string;

  minzoom: number;

  maxzoom: number;

  map: Map;

  tiles: Array<string>;

  _options: ModelsSourceSpecification;

  scene: Group;

  loadedTiles = new Set<string>();

  constructor(id: string, options: ModelsSourceSpecification) {
    this.id = id;
    this.minzoom = options.minzoom || 0;
    this.maxzoom = options.maxzoom || 22;
    this.tiles = options.tiles;

    this._options = options;
    this.scene = new Group();
  }

  onAdd(map: Map, renderer: WebGLRenderer) {
    this.map = map;
    KTX2_LOADER.detectSupport(renderer);
  }

  updateTiles() {
    if (this.tiles.length === 0) {
      return;
    }

    const tileSize = this.map.transform.tileSize;
    const visibleTiles = this.map.transform.coveringTiles({
      tileSize,
      minzoom: this.minzoom,
      maxzoom: this.maxzoom
    });

    for (const tile of visibleTiles) {
      if (!this.loadedTiles.has(tile.key)) {
        const gltf_loader = new GLTFLoader()
          .setKTX2Loader(KTX2_LOADER)
          .setMeshoptDecoder(MeshoptDecoder);
        const url = tile.canonical.url(this.tiles, this.map.getPixelRatio(), this._options.scheme);

        gltf_loader
          .loadAsync(url)
          .then((data) => {
            data.scene.traverse((c) => {
              if (isMesh(c)) {
                const material = c.material as MeshStandardMaterial;
                material.alphaTest = 0.1;
                material.side = BackSide;
                c.userData.originalMaterial = material;
              }
            });
            data.scene.userData = {
              isTile: true,
              key: tile.key
            };
            this.scene.add(data.scene);
          })
          .catch((reason) => {
            if (reason.response.status !== 404) {
              console.log(`Problem with loading ${tile.toString()}: ${reason.message}`);
            }
          });

        this.loadedTiles.add(tile.key);
      }
    }
  }
}
