import { Map, StyleSpecification } from 'maplibre-gl';
import {
  isModeledStyleSpecification,
  ModeledStyleSpecification,
  ModelsLayerSpecification,
  ModelsSourceSpecification
} from './Types';
import { ModelsLayer } from './ModelsLayer';

interface ModelsSpecification {
  sources: { [_: string]: ModelsSourceSpecification };
  layers: ModelsLayerSpecification[];
}

export const getOldSpecification = (style: StyleSpecification): ModelsSpecification => {
  let sources: { [_: string]: ModelsSourceSpecification } = {};
  const layers: ModelsLayerSpecification[] = [];

  style.layers.forEach((layer) => {
    const metadata = layer?.metadata as any;
    if (metadata?.['custom-sources']) {
      sources = {
        ...sources,
        ...metadata['custom-sources']
      };
    }

    if (metadata?.['custom-layers']) {
      metadata['custom-layers'].forEach((customLayer: ModelsLayerSpecification) => {
        if (customLayer.type === 'models') {
          layers.push({ ...customLayer, before: layer.id });
        }
      });
    }
  });

  return {
    sources,
    layers
  };
};

/**
 * Returns a ModeledStyleSpecification from a StyleSpecification. This will also
 * take care of migrating any old configurations.
 *
 * @param style The original StyleSpecification
 * @returns A ModeledStyleSpecification that is ensured to contain custom-sources and custom-layers
 */
export const cleanupSpecification = (style: StyleSpecification): ModeledStyleSpecification => {
  const configuration = getOldSpecification(style);

  const currentSources = isModeledStyleSpecification(style)
    ? style?.['custom-sources']
    : configuration.sources;
  const currentLayers = isModeledStyleSpecification(style)
    ? style?.['custom-layers']
    : configuration.layers;

  const newStyle: ModeledStyleSpecification = {
    ...structuredClone(style),
    'custom-sources': currentSources,
    'custom-layers': currentLayers
  };

  return newStyle;
};

export const updateModelsLayers = (map: Map, style: ModeledStyleSpecification) => {
  const sources = style['custom-sources'];  
  const layers = style['custom-layers'];

  layers.forEach((layer) => {
    if (map.style.hasLayer(layer.id)) {
      const modelLayer = map.getLayer(layer.id);
      (modelLayer as any).implementation.update(layer, sources[layer.source]);
    }
    else {
      const newLayer = new ModelsLayer(layer, sources[layer.source]);
      map.addLayer(newLayer);
    }
  });
};

export const addModelsSource = (
  style: ModeledStyleSpecification,
  sourceName: string,
  source: Partial<ModelsSourceSpecification>
) => {
  const sources = structuredClone(style['custom-sources']);

  const newSource: ModelsSourceSpecification = {
    type: 'models',
    tiles: [],
    ...source
  };

  sources[sourceName] = newSource;

  style['custom-sources'] = sources;

  return style;
};

export const addModelsLayer = (
  style: ModeledStyleSpecification,
  layer: Partial<ModelsLayerSpecification>
) => {
  const layers = structuredClone(style['custom-layers']);

  const newLayer: ModelsLayerSpecification = {
    id: 'dummy',
    type: 'models',
    before: style.layers[-1]?.id,
    source: Object.keys(style['custom-sources'])[0],
    ...layer
  };

  const newLayers = [...layers, newLayer];

  style['custom-layers'] = newLayers;

  return style;
};
