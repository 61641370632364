import { Map, NavigationControl, StyleSpecification } from 'maplibre-gl';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min';
import { ModelsLayer } from './Models/ModelsLayer';
import 'maplibre-gl/dist/maplibre-gl.css';
import { cleanupSpecification, updateModelsLayers } from './Models/functions';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ModelsLayerSpecification, ModelsSourceSpecification } from './Models/Types';


const urlParams = new URLSearchParams(window.location.search);
const styleUrl = urlParams.has("styleUrl") ? decodeURI(urlParams.get("styleUrl") as string) : undefined;

const styles = {
  active: 'dark',
  dark: 'https://api.tomtom.com/style/2/custom/style/dG9tdG9tQEBAQ1Z2MjZ5RUpmcWpqTDlEYTs2ZWU0OTI4YS1mZWE5LTQ3ZWQtOThkNi00Nzg1YjRhMDNmM2M=/drafts/0.json?key=Yia0TiKnv14KUwAXxXgyJguWiyOyoL0C',
  light: 'https://api.tomtom.com/style/2/custom/style/dG9tdG9tQEBAQ1Z2MjZ5RUpmcWpqTDlEYTtlNGY3Yzk5Zi1mMDk3LTQzOTItOGUyYS1mN2E5MTMxY2MwZGU=.json?key=Yia0TiKnv14KUwAXxXgyJguWiyOyoL0C'
}

fetch(styles.dark).then((response) => {
  response.json().then((style) => {
    styles.dark = style;
  });
});

fetch(styles.light).then((response) => {
  response.json().then((style) => {
    styles.light = style;
  });
});

const params = {
  tile_ids: false,
  style: {
    url: "",
    reload: () => {
      fetch(params.style.url).then((response) => {
        response.json().then((style) => {
          map.setStyle(style);
          initTiles(style);
        });
        const url = new URL(window.location.href);
        url.searchParams.set('styleUrl', params.style.url);
        window.location.href = url.toString();
      });
    }
  }
};

const initOptionsGui = (): GUI => {
  const gui = new GUI();
  gui.title("Options");

  const style = gui.addFolder("Custom Style");
  style.add(params.style, "url");
  style.add(params.style, "reload");

  const debug = gui.addFolder("Debug");
  debug.add(params, 'tile_ids').onChange(checked => {
    map.showTileBoundaries = checked;
  });
  gui.close();

  return gui;
}

let map: Map;

const initLoader = () => {

  const isExtension = (filename: string, extension: string) => {
    return filename.split( '.' ).pop().toLowerCase() === extension.toLowerCase();
  }

  const onDrop = (e: DragEvent) => {
    e.preventDefault();

    if (e.dataTransfer) {
      for (const file of e.dataTransfer.files) {
        if (isExtension(file.name, 'glb')) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          reader.onload = (ev) => {
            const loader = new GLTFLoader();
            if (ev.target) {
              loader.parse(ev.target.result as ArrayBuffer, '', (gltf) => {
                let gltfLayer = map.getLayer('gltf');

                if (gltfLayer == undefined) {
                  const dummySource: ModelsSourceSpecification = {
                    type: 'models',
                    tiles: [],
                  };
  
                  const dummyLayer: ModelsLayerSpecification = {
                    id: 'gltf',
                    type: 'models',
                    before: map.getStyle().layers[-1]?.id,
                    source: 'dummy'
                  };
                  const newLayer = new ModelsLayer(dummyLayer, dummySource);
                  map.addLayer(newLayer);
                }

                gltfLayer = map.getLayer('gltf');
                gltfLayer.implementation.addModel(gltf.scene);
              });
            }
              
          }

        }
      }
    }

  };
  
  const mapElement = window.document.getElementById( "map" ) as HTMLElement;
	mapElement.ondragover = e => e.preventDefault();
	mapElement.ondrop = onDrop;

}

const initTiles = (style: StyleSpecification) => {
  updateModelsLayers(map, cleanupSpecification(style));
}

const syncMap = (source: Map, destination: Map) => {
  destination.jumpTo({
    center: source.getCenter(),
    zoom: source.getZoom() - 3,
    bearing: source.getBearing(),
    pitch: source.getPitch()
  });
};

const init = (style: StyleSpecification) => {
	map = new Map({
		container: 'map',
		style: style,
		center: [ 0, 0 ],
		zoom: 0,
    hash: true
	});

  const minimap = new Map({
    container: 'minimap',
		style: styles['light'],
		center: [ 0, 0 ],
		zoom: 0,
    interactive: false
	});
  
  map.on('move', () => {
    syncMap(map, minimap);
  });

  const minimapElement = window.document.getElementById( "minimap" ) as HTMLElement;
  minimapElement.onpointerup = () => {
    styles.active = styles.active == 'dark' ? 'light' : 'dark';
    map.setStyle(styles[styles.active]);
    minimap.setStyle(styles[styles.active == 'dark' ? 'light': 'dark']);
    initTiles(styles[styles.active]);
  };

  map.setMaxPitch(80);

  map.addControl(new NavigationControl({visualizePitch: true}), 'bottom-right');

  map.on('style.load', () => {
    initTiles(style);
    syncMap(map, minimap);
  });
};

fetch(styleUrl || styles['dark']).then((response) => {
  response.json().then((style) => {
    init(style);
    initLoader();
  });
});
initOptionsGui();
